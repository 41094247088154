import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";

declare global {
    interface Window {
        angBridge: any;
    }
}

export interface JwtTokenHolder {
    jwtToken?: string;
    jwtRefreshToken?: string;
    jwtTokenHash?: string;
    tokenExpirationUTC?: string;
    refreshTokenExpirationUTC?: string;
}

// TODO: This is a proxy class to AngularJS AuthService
// TODO: Once AuthService is ready to be migrated this proxy will need to be replaced
export class AuthService {
    public static authenticate(username: string, password: string) {
        return window.angBridge.$injector.get("AuthService").authenticate(username, password);
    }

    public static invalidateAuth() {
        return window.angBridge.$injector.get("AuthService").invalidateAuth();
    }

    public static masqueradeLogin() {
        return window.angBridge.$injector.get("AuthService").masqueradeLogin();
    }

    public static getRedirectUrl() {
        let redirectUrl = "https://" + window.location.host;
        let gauss = window.location.pathname.indexOf("25power-") > -1;

        if (window.location.host === "localhost" || (!gauss && window.location.host.indexOf("unival") > -1)) {
            if (S25Const.isDevMode) {
                redirectUrl += "/25power/src/main/webapp/oauth/";
            } else {
                redirectUrl += "/25power/oauth/";
            }
        } else if (gauss) {
            redirectUrl += "/" + window.location.pathname.split("/").filter(S25Util.toBool)[0] + "/oauth/";
        } else {
            redirectUrl += "/pro/oauth/";
        }

        // note: skipIndexController=oauth is required here bc Freshbooks whitelists the
        //       urls that are OK to use for redirect after successful oauth and that parameter
        //       is used in the current FB list and I do not know how or where to edit that list,
        //       so we must keep the exact same url structure for now
        return redirectUrl + "index.html?skipIndexController=oauth";
    }

    public static SSOWindow(loginUrl: string, sessionId: string | number) {
        return window.angBridge.$injector.get("AuthService").SSOWindow(loginUrl, sessionId);
    }

    public static getJwtTokenHolder(): Promise<JwtTokenHolder> {
        return DataAccess.post("/device/jwt.json");
    }

    public static refreshJwt(jwtTokenHolder: JwtTokenHolder): Promise<JwtTokenHolder> {
        // @RequestBody in 25saas requires a wrapper object with the target class name
        // (unless it's a subclass of GenericDataAccessPojo)
        return DataAccess.post("/device/refresh.json", { JwtTokenHolder: jwtTokenHolder });
    }
}
