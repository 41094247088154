import { DataAccess } from "../dataaccess/data.access";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";
import { UserprefService } from "./userpref.service";
import { LoginService } from "./login.service";
import { Timeout } from "../decorators/timeout.decorator";
import { PersistentSessionService } from "./persistent.session.service";
import { S25ItemI } from "../pojo/S25ItemI";
import { Contact } from "../pojo/Contact";
import { S25WsNode } from "../pojo/S25WsNode";
import { Proto } from "../pojo/Proto";
import NumericalString = Proto.NumericalString;
import { S25Const } from "../util/s25-const";

declare global {
    interface Window {
        angBridge: any;
    }
}

const CONTACT_ARRAYS = {
    contact: true,
    address: true,
};

export class ContactService {
    @Invalidate({ serviceName: "ContactService" })
    @Timeout
    public static putContact(itemId: number, payload: any) {
        return ContactService.getContactAsSingle(itemId, "normal", []).then(function (item) {
            S25Util.coalesceDeep(payload, item);
            delete payload.crc;
            return DataAccess.put(
                DataAccess.injectCaller("/contact.json?contact_id=" + itemId, "ContactService.putContact"),
                S25Util.getPayload("contacts", "contact", "contact_id", "mod", itemId, payload),
            );
        });
    }

    //used to address race condition, does not do coalesce step that generic putContact does - WS will ignore any fields not included
    @Invalidate({ serviceName: "ContactService" })
    @Timeout
    public static putContactMinimal(itemId: number, payload: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/contact.json?contact_id=" + itemId, "ContactService.putContactMinimal"),
            S25Util.getPayload("contacts", "contact", "contact_id", "mod", itemId, payload),
        );
    }

    @Timeout
    public static deleteContact(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/contact.json?contact_id=" + id, "ContactService.deleteContact"),
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                var isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "CU_I_DELETED";
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                console.log(error);
                return { error: error, success: false };
            },
        );
    }

    public static getContactAsSingle(id: number, scope: string, includes: string[]) {
        return ContactService.getContact(id, scope, includes).then(function (data) {
            return data && data.contacts && data.contacts.contact;
        });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getContact(id: number, scope?: string, includes?: string[]) {
        return ContactService.getContactFresh(id, scope, includes);
    }

    public static getContactFresh(id: number, scope?: string, includes?: string[]) {
        if (includes) {
            scope = "extended";
        }
        return DataAccess.get(
            DataAccess.injectCaller(
                "/contact.json?contact_id=" +
                    id +
                    (scope ? "&scope=" + scope : "") +
                    (includes ? "&include=" + includes.join("+") : ""),
                "ContactService.getContact",
            ),
        ).then(function (data) {
            data = S25Util.replaceDeep(data, {
                r25_username: S25Util.toStr,
                userName: S25Util.toStr,
                username: S25Util.toStr,
            });
            return S25Util.prettifyJson(data, null, { address: true, web_users: true });
        });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getContactNameAndTitle(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/contact.json?contact_id=" + id + "&scope=minimal", "ContactService.getContact"),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data);
            return {
                itemName: S25Util.propertyGetVal(data, "contact_name"),
                itemFormal: S25Util.propertyGetVal(data, "title"),
            };
        });
    }

    public static getContactName(id: number) {
        return ContactService.getContactNameAndTitle(id).then(function (data) {
            return data.itemName;
        });
    }

    public static getContactTitle(id: number) {
        return ContactService.getContactNameAndTitle(id).then(function (data) {
            return data.itemFormal;
        });
    }

    public static async getContactsNameAndFormal(ids: number[]): Promise<S25ItemI[]> {
        if (!ids.length) return [];
        ids = S25Util.array.unique(ids);
        const objs: WSContact[] = await ContactService.getContactsMinimal(ids);
        const objIdMap = S25Util.fromEntries(objs.map((obj) => [obj.contact_id, obj]));
        const idIndexMap = S25Util.fromEntries(ids.map((id, index) => [id, index]));
        return (objs ?? [])
            .map((obj) => ({ itemId: obj.contact_id, itemName: obj.contact_name, itemDesc: obj.title }))
            .concat(
                ids
                    .filter((id) => !objIdMap[id])
                    .map((id) => ({ itemId: id, itemName: S25Const.private, itemDesc: "" })),
            )
            .sort((a, b) => {
                return idIndexMap[S25Util.toInt(a.itemId)] - idIndexMap[S25Util.toInt(b.itemId)];
            });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getContactsMinimal(idArray: number[]) {
        return DataAccess.post(
            DataAccess.injectCaller("/contacts.json?request_method=get", "ContactService.getContactsMinimal"),
            S25Util.uglifyJson({ mapxml: { contact_id: idArray.join("+"), scope: "minimal" } }),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, CONTACT_ARRAYS);
            return (data && data.contacts && data.contacts.contact) || [];
        });
    }

    public static getEmailCount(email: string) {
        return DataAccess.get(
            DataAccess.injectCaller(`/contacts.json?email=${email}&scope=count`, "ContactService.getEmailCount"),
        ).then((data) => {
            return data?.results?.contacts;
        });
    }

    public static getContactByEmail(email: string, lastName?: string) {
        const lastNameQuery = lastName ? `&last_name=${lastName}` : "";

        return DataAccess.get(
            DataAccess.injectCaller(
                `/contacts.json?email=${email}${lastNameQuery}&scope=extended`,
                "ContactService.getContactByEmail",
            ),
        ).then((resp) => resp);
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getContactEmail(id: number) {
        return ContactService.getContact(id, "extended", ["address"]).then(
            function (data) {
                return (
                    S25Util.propertyGetVal(S25Util.propertyGetParentWithChildValue(data, "address_type", 3), "email") ||
                    null
                );
            },
            function (error) {
                return null;
            },
        );
    }

    //cached (note non-logged-in users have no access to contacts.xml so will get a basic-auth prompt...)
    public static getContactUsername(id: number) {
        return ContactService.getContact(id, "extended", ["user"]).then(function (data) {
            return S25Util.propertyGetVal(data, "r25_username");
        });
    }

    public static getContactByUsername(username: string, includes?: string[]) {
        //TODOS: support a list parameters
        return DataAccess.get(
            DataAccess.injectCaller(
                "/contacts.json?scope=extended&include=user&r25_username=" + username,
                "ContactService.getUserContactId",
            ),
        ).then((resp) => {
            return resp && resp.contacts && resp.contacts.contact && resp.contacts.contact;
        });
    }

    public static getFilterContacts(ids: number[], includes?: string[]) {
        var url = "/contacts.json?contact_id=" + ids.join("+");
        var includesList = includes && includes.join("+");
        if (includesList) {
            url += "&scope=extended&include=" + includesList;
        }
        return DataAccess.get(DataAccess.injectCaller(url, "ContactService.getFilterContacts")).then(function (data) {
            data = S25Util.prettifyJson(data);
            if (data.contacts) {
                data.contacts.contact = S25Util.array.forceArray(data.contacts.contact);
            }
            return data;
        });
    }

    public static getContactsByGroup(groupIds: number[]): Promise<S25ItemI[]> {
        const url = `/r25users.json?group_id=${groupIds.join("+")}&scope=list`;
        return DataAccess.get(DataAccess.injectCaller(url, "getContactsByGroup")).then((resp: any) => {
            return (resp?.list?.item || []).map((item: { id: string; name: string }) => {
                return { itemId: Number(item.id), itemName: item.name };
            });
        });
    }

    //separate method so we can cache it
    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentContact(scope: string, includes: string[]) {
        return UserprefService.getContactId().then(function (contactId) {
            return ContactService.getContactFresh(contactId, scope, includes);
        });
    }

    //faster method (missing email and address info)
    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentContactSimple() {
        return UserprefService.getLoggedIn().then(function (isLoggedIn) {
            return (
                isLoggedIn &&
                DataAccess.get(
                    DataAccess.injectCaller("/current/contact.json", "ContactService.getCurrentContactSimple"),
                )
            );
        });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentId() {
        return UserprefService.getContactId();
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentEmail() {
        return ContactService.getCurrentContact(null, ["address"]).then(
            function (data) {
                return (
                    S25Util.propertyGetVal(S25Util.propertyGetParentWithChildValue(data, "address_type", 3), "email") ||
                    null
                );
            },
            function (error) {
                return null;
            },
        );
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentName() {
        //cached (note non-logged-in users have no access to contacts.xml so will get a basic-auth prompt...)
        return ContactService.getCurrentContactSimple().then(function (data) {
            data = data && data.contacts && data.contacts.contact;
            return (data && data.contact_name && data.contact_name.length > 0 && data.contact_name) || "";
        });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    public static getCurrentNameStyled() {
        //cached (note non-logged-in users have no access to contacts.xml so will get a basic-auth prompt...)
        return ContactService.getCurrentContactSimple().then(function (data) {
            data = data && data.contacts && data.contacts.contact;
            var firstName = (data && data.first_name) || "";
            var lastName = (data && data.last_name) || "";
            return firstName + (firstName ? " " : "") + lastName;
        });
    }

    @Cache({ immutable: true, targetName: "ContactService" })
    /**
     * Fetches the current user's username
     * @CachedImmutable
     * @return {string}
     */
    public static async getCurrentUsername() {
        const data = await LoginService.getLoginCached();
        return data.userName;
    }

    /*
    Get the organizations the current user is associated with - optionally filter by role_id
    Note that user can be associated without having a role
     */
    @Cache({ immutable: true, targetName: "ContactService" })
    public static async getCurrentAssociatedOrganizations(roleIds?: number[]) {
        const resp = await ContactService.getCurrentContact("extended", ["organizations"]);
        const orgs: Contact.Organization[] = S25Util.array.forceArray(resp?.contacts?.contact?.organization);
        return roleIds?.length > 0 ? orgs.filter((org) => roleIds?.includes(org.role_id)) : orgs;
    }

    @Timeout
    public static async isService25() {
        return (await ContactService.getCurrentUsername()) === "service25";
    }

    public static setPassword(password: string) {
        return S25Util.all({
            username: ContactService.getCurrentUsername(),
            contId: ContactService.getCurrentId(),
            userType: UserprefService.getUserTypeWeb(),
        }).then(function (resp) {
            return ContactService.setPasswordHelper(password, resp.contId, resp.username, resp.userType);
        });
    }

    private static setPasswordHelper(password: string, contactId: number, username: any, userTypeWeb: any) {
        let url = "/r25user.json?r25_username=" + username;
        let payLoad = {
            r25users: {
                r25_user: [
                    {
                        contact_id: contactId,
                        r25_username: username,
                        r25_password: password,
                        active: 1,
                    },
                ],
            },
        };
        return DataAccess.put(DataAccess.injectCaller(url, "ContactService.setPassword"), payLoad);
    }

    @Invalidate({ serviceName: "ContactService" })
    public static setContact(contactModel: any, isNew?: boolean) {
        let url = "/contact.json",
            status = "new";
        if (!isNew) {
            status = "mod";
            url += "?contact_id=" + contactModel.contact_id;
        }

        contactModel.crc = null;
        contactModel.id = null;
        contactModel.status = status;
        let payLoad = { contacts: { contact: contactModel } };
        if (isNew) {
            return DataAccess.post(DataAccess.injectCaller("/contacts.json", "ContactService.setContact")).then(
                function (newContact) {
                    contactModel.contact_id = S25Util.propertyGetVal(S25Util.prettifyJson(newContact), "contact_id");
                    url += "?contact_id=" + contactModel.contact_id;
                    return DataAccess.put(DataAccess.injectCaller(url, "ContactService.setContact"), payLoad);
                },
            );
        } else {
            return DataAccess.put(DataAccess.injectCaller(url, "ContactService.setContact"), payLoad);
        }
    }

    /**
     *
     * @param itemId
     * @param newItem
     * @param fields
     * @param security -- not used since there is no OLS on security. Argument exists for easier copy.object.service
     */
    public static copyContact = function (itemId: number, newItem: any, fields?: any, securityParams?: string) {
        //TODO: add "user"
        return ContactService.getContact(itemId, "extended", ["address", "organizations", "attributes", "text"]).then(
            (orig) => {
                let newContact = S25Util.deepCopy(orig.contacts.contact);
                let nameParts = [
                    "name_prefix",
                    "first_name",
                    "middle_name",
                    "last_name",
                    "name_suffix",
                    "title",
                    "internal_id",
                ];
                nameParts.forEach((part) => {
                    newContact[part] = newItem[part] || "";
                });
                let persistWorkAddr = S25Util.propertyGetParentWithChildValue(newItem, "address_type", "3");
                let persistHomeAddr = S25Util.propertyGetParentWithChildValue(newItem, "address_type", "4");

                let origWorkAddr = S25Util.propertyGetParentWithChildValue(newContact, "address_type", "3");
                let origHomeAddr = S25Util.propertyGetParentWithChildValue(newContact, "address_type", "4");

                if (origWorkAddr) {
                    origWorkAddr.email = persistWorkAddr && persistWorkAddr.email;
                }

                if (origHomeAddr) {
                    origHomeAddr.email = persistHomeAddr && persistHomeAddr.email;
                } else if (persistHomeAddr && persistHomeAddr.email) {
                    origHomeAddr = { email: persistHomeAddr.email };
                    newContact.address.push(S25Util.addressObjToNode(origHomeAddr, 4));
                }

                delete newContact.crc;
                newContact.contact_id = "";

                S25Util.replaceDeep(newContact, { status: "new" });

                return ContactService.setContact(newContact, true).then((resp) => {
                    return resp?.results?.info?.id;
                });
            },
        );
    };

    @Timeout
    public static async createPersistentSession(contactId: number) {
        const username = await ContactService.getContactUsername(contactId);
        const [data, error] = await S25Util.Maybe(PersistentSessionService.create(username));
        if (error) return S25Util.showError(error);
        return S25Util.propertyGetVal(data, "session_id");
    }

    @Timeout
    public static async deletePersistentSession(contactId: number) {
        const username = await ContactService.getContactUsername(contactId);
        const [data, error] = await S25Util.Maybe(PersistentSessionService.delete(username));
        if (error) return S25Util.showError(error);
        return S25Util.propertyGetVal(data, "session_id");
    }
}

export type WSContact = S25WsNode & {
    contact_id: number | NumericalString;
    contact_name?: string;
    title?: string;
};
