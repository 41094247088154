//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Timeout } from "../decorators/timeout.decorator";

export interface FileItem {
    itemId: string;
    itemName: string;
    itemTypeId: number;
    objectTypeId: string | number;
    objectId: string | number;
}

export class AttachmentService {
    @Timeout
    public static getFileList(objectTypeId: number, objectId: number): Promise<FileItem[]> {
        return new Promise((resolve) => {
            DataAccess.get(
                DataAccess.injectCaller(
                    "/fs/list/file_list.json?" +
                        DataAccess.composeParam("object_type", objectTypeId, true) +
                        DataAccess.composeParam("object_id", objectId),
                    "AttachmentDao.getFileList",
                ),
            ).then(
                function (data) {
                    resolve(
                        AttachmentService.composeFileListObject(
                            S25Util.prettifyJson(data, null, { file: true }).file_list.file,
                            objectTypeId,
                            objectId,
                        ),
                    );
                },
                function () {
                    resolve(undefined);
                },
            );
        });
    }

    public static composeFileListObject(list: any, objectTypeId: string | number, objectId: number): FileItem[] {
        if (!list) {
            return undefined;
        }
        return list.map(function (item: any) {
            Object.assign(item, {
                itemId: item.external_id,
                itemName: item.file_name,
                itemTypeId: 1000 + S25Util.parseInt(objectTypeId),
                objectTypeId: objectTypeId, // parent typeId, i.e. event=1, space=4, etc.
                objectId: objectId, // parent id, i.e. eventId, spaceId, etc.
            });
            delete item.file_name;
            delete item.external_id;
            return item;
        });
    }

    public static getFileUrl(objectTypeId: number, objectId: number, itemName: string, display?: string) {
        return DataAccess.getUrl(
            DataAccess.injectCaller(
                "/fs/download/download_file.xml?" +
                    DataAccess.composeParam("object_type", objectTypeId, true) +
                    DataAccess.composeParam("object_id", objectId) +
                    DataAccess.composeParamUrlEncode("file_name", itemName) +
                    (display ? DataAccess.composeParam("display", display) : ""),
                "AttachmentDao.getFileUrl",
            ),
        );
    }

    public static getFile(objectTypeId: number, objectId: number, itemName: string, display?: string): Promise<Blob> {
        return DataAccess.get(this.getFileUrl(objectTypeId, objectId, itemName, display), null, null, "blob").then(
            (resp) => resp,
            (error) => null,
        );
    }

    public static getFileIfExists(
        objectTypeId: number,
        objectId: number,
        itemName: string,
        display?: string,
    ): Promise<Blob> {
        return this.getFileList(objectTypeId, objectId).then((list) => {
            if (list && list.length > 0) {
                if (list.some((item) => item.itemName === itemName)) {
                    return this.getFile(objectTypeId, objectId, itemName, display);
                }
            }
            return null;
        });
    }

    public static async getFileTextIfExists(
        objectTypeId: number,
        objectId: number,
        itemName: string,
        display?: string,
    ): Promise<string> {
        const blob = await this.getFileIfExists(objectTypeId, objectId, itemName, display);
        return blob?.text();
    }

    //writes:
    public static postFile(objectTypeId: number, objectId: number, file: any, fileName?: string) {
        if (typeof file === "string" && fileName) {
            let blob = new Blob([file], { type: "text/plain" });
            file = new File([blob], fileName, { type: "text/plain" });
        }
        return DataAccess.postFile(
            DataAccess.injectCaller(
                "/fs/upload/upload_file.json?object_type=" + objectTypeId + "&object_id=" + objectId,
                "AttachmentDao.postFile",
            ),
            file,
        );
    }

    public static delFile(objectTypeId: number, objectId: number, itemName: string) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/fs/delete/delete_file.json?" +
                    DataAccess.composeParamUrlEncode("file_name", itemName, true) +
                    DataAccess.composeParam("object_type", objectTypeId) +
                    DataAccess.composeParam("object_id", objectId),

                "AttachmentDao.delFile",
            ),
        );
    }
}
