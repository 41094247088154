import { LooseAutocomplete, ValueOf } from "./Util";
import { OrganizationI } from "./OrganizationI";
import { EventI } from "./EventI";
import { PriceSheetI } from "./RateScheduleI";
import { Fls } from "./Fls";
import { Proto } from "./Proto";
import ISODateString = Proto.ISODateString;
import NumericalBoolean = Proto.NumericalBoolean;
import NumericalString = Proto.NumericalString;
import { S25WsNode } from "./S25WsNode";
import { IPaymentDetails } from "../modules/s25-pricing/s25-payments/payment.service";
import { Contact } from "./Contact";
import ContactRoleI = Contact.ContactRoleI;

export type MicroBilling = {
    data: { items: Invoice[] };
    expandedInfo: {
        rateGroups?: RateGroup[];
        rateSchedules?: RateSchedule[];
        organizations?: Organization[];
        occurrences?: Occurrence[];
        profiles?: Profile[];
    };
    id: number;
    updated: ISODateString;
};

export type RateGroupListItem = {
    defn_state: NumericalBoolean;
    rate_group_name: string;
    rate_group_id: number;
    sort_order: number;
    organization_code: string;
};

export type TaxListItem = {
    tax_name: string;
    tax_id: number;
    current_rate: number;
    rate_groups: {
        id: number;
        name: string;
    }[];
};

export type BillingItem = {
    adjustments?: Adjustment[];
    lineItems: LineItem[];
    subtotals: SubTotal[];
    totals: Total;
};

export type Adjustment = {
    adjustmentAmt?: number;
    adjustmentPercent?: number;
    adjustmentName?: string;
    chargeToId: number;
    itemId: number;
    totalCharge: number;
};

export type SubTotal = {
    accountEvent: AccountSubTotal[];
    accountOccurrence?: AccountOccurrenceSubtotal[];
    account: AccountSubTotal[];
    accountProfile: AccountProfileSubTotal[];
    profile: ProfileSubTotal[];
    occurrence: OccurrenceSubtotal[];
};

export type AccountOccurrenceSubtotal = OccurrenceSubtotal & {
    chargeToId: number;
};

export type OccurrenceSubtotal = {
    occurrenceAdjustments: number;
    occurrenceListPrice?: number;
    occurrenceTotalCharge?: number;
    rsrvId: number;
    chargeToId?: number;
};

export type AccountSubTotal = Total & {
    chargeToId: number;
    invoiceId: string;
    eventId?: number;
    outstandingBalance?: number;
    eventsTotalCharge?: number;
};

export type AccountProfileSubTotal = {
    chargeToId: number;
    profileId: number;
    occurrenceListPrice?: number;
    occurrenceAdjustments: number;
    occurrenceTotalCharge?: number;
    profileAdjustments: number;
    profileTotalCharge: number;
};

export type ProfileSubTotal = AccountProfileSubTotal;

export type Total = {
    occurrenceListPrice: number;
    occurrenceAdjustments: number;
    occurrenceTotalCharge: number;
    profileAdjustments: number;
    profileTotalCharge: number;
    requirementsListPrice: number;
    requirementsAdjustments: number;
    requirementsTotalCharge: number;
    taxableAmount: number;
    tax: number;
    grossAdjustments: number;
    grandTotal: number;
};

export type TotalsModel = {
    combineRelatedEvents?: boolean;
    orgs?: OrganizationI[];
    evBillId?: number;
    rows?: LineItemI[];
    grandPriceList?: number;
    grandAdjustmentAmt?: number;
    grandTaxableAmt?: number;
    grandTotalCharge?: number;
    grandTotalPayments?: number;
    grandTotalBalance?: number;
    grandTaxes?: {
        tax: TaxTotal[];
    };
    taxesMap?: string[];

    [key: string]: any;
};

export type LineItem = {
    adjustmentAmt?: number;
    adjustmentPercent?: number;
    adjustmentName?: string;
    chargeToId?: number;
    creditAccountNumber?: string;
    debitAccountNumber?: string;
    eventId?: number;
    itemId?: number;
    itemName?: string;
    itemType?: LineItemType;
    occurrences?: PricingOccurrence[];
    profileId?: number;
    rateGroupId?: number;
    rateId?: number;
    totalCount?: number;
    totalTime?: string;
    listPrice?: number;
    price?: number;
    total?: number;
    taxes?: TaxItem[];

    [key: string]: any;
};

export type LineItemType = ValueOf<typeof lineItemType>;
export const lineItemType = {
    ADJUSTMENT: -1,
    TOTAL: 0,
    EVENT: 1,
    REQUIREMENT: 2,
    LOCATION: 3,
    RESOURCE: 4,
};

export interface PricingOccurrence extends LineItemI {
    adjustmentAmt: number;
    adjustmentPercent: number;
    adjustmentName: string;
    listPrice: number;
    price: number;
    rsrvId: number;
    total: number;
    totalTime: string;
    occurrence: string;
    tableDisplay?: string;
}

export interface LineItemI extends PricingModel {
    adjustment_amt?: any;
    adjustment_name?: string;
    adjustment_percent?: any;
    bill_item_id?: number;
    bill_item_type_id?: LineItemType;
    bill_item_type_name?: "Requirement" | "Location" | "Resource" | "Event";
    bill_profile_name?: string;
    charge_to_id?: number;
    charge_to_name?: string;
    credit_account_number?: string;
    debit_account_number?: string;
    ev_dt_profile_id?: number;
    list_price?: number;
    occurrences?: LineItem[];
    rate_group_id?: NumericalString;
    rate_group_name?: string;
    rate_id?: NumericalString;
    rate_name?: string;
    tax?: TaxItemI[];
    taxable_amt?: number;
    total_charge?: number;
    total_count?: number;
    total_tax?: number;
    paymentMapAction?: {
        action: "map" | "convert" | "exclude";
        status?: "paid" | "unpaid";
        type?: "misc" | "deposit" | "final";
        dueDate?: string;
        datePaid?: string;
    };
}

export type TaxItemI = {
    tax_id: number;
    tax_charge?: number;
    tax_name: string;
    status?: S25WsNode["status"];
};

export type TaxItem = {
    taxId: number;
    taxCharge: number;
    taxName: string;
};

export type RateGroup = {
    rateGroup_id: number;
    etag: string;
    rateGroupName: string;
    acctCode: string;
};

export type RateSchedule = {
    rateId: number;
    etag: string;
    rateName: string;
};

type Organization = {
    organizationId: number;
    etag: string;
    organizationName: string;
    organizationTitle: string;
    organizationTypeId: number;
};

type Profile = {
    profileId: number;
    name: string;
};

type Occurrence = {
    evEndDt: string;
    evStartDt: string;
    occurrence: string;
    rsrvEndDt: string;
    rsrvStartDt: string;
    rsrvId: number;
    state: number;
    setNames?: Set<string>;
};

export type BillDefinition = {
    billDate?: ISODateString;
    billName: string;
    completed?: boolean;
    events?: number[];
    includeEventType?: boolean;
    includeRequirements?: boolean;
    includeResources?: boolean;
    includeSpaces?: boolean;
    requirements?: { requirementId: number }[];
    reservations?: { event_id: number; rsrv_id: number }[];
    resources?: { resourceId: number }[];
    spaces?: { spaceId: number }[];
};

export interface PricingModel {
    defaultPricingSet?: Invoice;
    chosenPricingSet?: Invoice;
    canCreatePricingSet?: boolean;
    hasCreatePricingSet?: boolean;
    toggleCreatePricingSet?: () => void;
    getPricingSets?: () => Promise<void>;
    selectPricingSet?: () => void;
    deletePricingSet?: () => Promise<void>;
    dateTimeFormat?: string;
    pricingSet?: {
        itemId: number;
    };
    pricingSets?: Invoice[];
    canAdjust?: boolean;
    canEdit?: boolean;
    canEditPricing?: boolean;
    eventId?: number;
    orgId?: number;
    orgName?: string;

    [key: string]: any;
}

export interface PricingOrgData extends PricingModel {
    allOrgs?: OrganizationI[];
    allBillItems?: LineItemI[];
    rows?: LineItemI[];
    eventData?: EventI;
    rateGroups?: PriceSheetI[];
    isAdjustment?: boolean;
    orgId?: number;
    taxesMap?: string[];
    fls?: Fls;

    [key: string]: any;
}

export type PricingOrg = {
    canEdit: boolean;
    caption: "Pricing List";
    comptype: "pricing";
    evBillId: number;
    data: PricingOrgData;
    invoiceNum: string;
    canEditInvoiceNum: boolean;
    orgId: number;

    [key: string]: any;
};

export type UpdateData = {
    [key: number]: {
        occSubtotals: any;
        footerData: any;
        rows: LineItemI[];
    };

    adjustments: Adjustment[];
    bill_item_id: number;
    newChargeToId: number;
    orgId: number;
    subtotals: Total[];
    taxData: TaxTotals;
    totals: Total;
    combineRelatedEvents: boolean;
    profileSubtotals: AccountProfileSubTotal[];
    adjustmentId: number;
};

export type TaxTotals = {
    [key: number]: TaxTotal;
};

export type TaxTotal = {
    itemName: string;
    itemValue: string;
    tax_charge: number;
    tax_id: number;
};

export interface EventPricingData extends EventI {
    occSubtotals: OccurrenceSubtotal[];
    occurrences: Occurrence[];
    profileSubtotals: AccountProfileSubTotal[];
}

// Native Invoices
export type InvoiceUpdateData = {
    billDate: string;
    billId: number;
    billName: string;
    completed: boolean; // invoice locked
    emptyRsrvList: boolean;
    eventIdList: number[];
    includeEventType: boolean;
    includeResources: boolean;
    includeSpaces: boolean;
    lockRsrvList: boolean;
    month: number;
    year: number;
    remove: { resourceIdList: number[]; spaceIdList: number[] };
    requirementIdList: number[];
    resourceIdList: number[];
    rsrvIdList: number[];
    spaceIdList: number[];
};

export type Invoice = {
    id: number;
    kind?: LooseAutocomplete<"event" | "invoice">;
    billing?: BillingItem;
    billDefn?: BillDefinition; // Only with pricing sheets
    payments?: IPaymentDetails[];
    updated?: ISODateString;
};

export type InvoiceSummaryData = {
    invoicedItems: {
        occs: Set<number>;
        lineItems: Set<string>;
        invoiceNamesAndIds: InvoiceNamesAndIds[];
    };
    lineItems: { [key: string]: LineItem };
    orgs?: OrganizationI[];
    rateGroups: RateGroup[];
    rateSchedules: RateSchedule[];
    subtotals: {
        [key: number]: InvoiceSummarySubtotal;
    };
};

export type InvoiceSummarySubtotal = Total &
    AccountSubTotal & {
        eventTypeListPrice: number;
        eventTypeAdjustments: number;
        eventTypeTotalCharge: number;
        occurrenceLineItems: LineItem[];
        nonOccurrenceLineItems: LineItem[];
    };

export type InvoiceNamesAndIds = {
    name: string;
    id: number;
    includeEventType: boolean;
    rsrvIds: Set<number>;
    reqIds: Set<number>;
    resourceIds: Set<number>;
    spaceIds: Set<number>;
    locked: boolean;
};

//FreshBooks Invoices
export type FBInvoiceData = {
    customBillInvoices: FBInvoice[];
    eventInvoices: FBInvoice[];
    setInvoices: FBInvoice[];
};

export type FBInvoice = {
    event_id: number;
    ev_bill_id: number;
    instance_id: number;
    invoice_id: number;
    organization_id: number;
};

export type FBInvoiceDisplayData = {
    accountid: number;
    invoice_id: number;
    date: string;
    links: { view: FBInvoiceLink; edit: FBInvoiceLink };
    number: string;
    status: "paid" | "unpaid";
    paid: number;
    amount_outstanding: number;
};

export type FBInvoiceLink =
    `https://my.freshbooks.com/#/invoice/${FBInvoiceDisplayData["accountid"]}_${FBInvoiceDisplayData["invoice_id"]}`;
