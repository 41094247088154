import { DataAccess } from "../dataaccess/data.access";
import { UserprefService } from "./userpref.service";
import { CordovaService } from "./cordova.service";
import { S25Const } from "../util/s25-const";

export enum DeviceType {
    IOS = "IOS",
    ANDROID = "ANDROID",
    UNKNOWN = "UNKNOWN",
}

export class DeviceService {
    public static async registerTokenWithStrType(deviceToken: string, deviceType: keyof typeof DeviceType) {
        await S25Const.authDefer.promise; // wait for login so we have a contact id
        let contId = await UserprefService.getContactId();
        let S25AppDeviceTokenKey = "S25AppDeviceToken." + S25Const.instanceId + "." + contId;
        let prevToken = await CordovaService.getSecureStorage(S25AppDeviceTokenKey);
        if (!prevToken || prevToken !== deviceToken) {
            await CordovaService.setSecureStorage(S25AppDeviceTokenKey, deviceToken);
            return this.registerToken(deviceToken, DeviceType[deviceType as keyof typeof DeviceType]);
        }
    }

    private static async registerToken(deviceToken: string, deviceType: DeviceType) {
        if (deviceType !== DeviceType.IOS && deviceType !== DeviceType.ANDROID) {
            console.error("Invalid device type: " + deviceType);
            return;
        }
        return DataAccess.post(
            "/device.json?deviceToken=" +
                encodeURIComponent(deviceToken) +
                "&deviceType=" +
                encodeURIComponent(deviceType),
        );
    }
}
