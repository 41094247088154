import { AuthService, JwtTokenHolder } from "./auth.service";
import { S25Util } from "../util/s25-util";
import { S25Const } from "../util/s25-const";
import { DataAccess } from "../dataaccess/data.access";

export interface SecureStorage {
    get(success: (value: string) => void, error: (error: any) => void, key: string): void;
    set(success: () => void, error: (error: any) => void, key: string, value: string): void;
    remove(success: () => void, error: (error: any) => void, key: string): void;
}

export class CordovaService {
    private static checkingJwt = false;
    private static jwtCheckIntervalMs = S25Const.ms.min;
    private static expirationBufferMs = S25Const.ms.hour;
    private static S25AppJwtTokenHolder = "S25AppJwtTokenHolder";
    private static ss: SecureStorage = null;
    private static activeRefresh = true;

    public static setSS(ss: SecureStorage) {
        CordovaService.ss = ss;
        if (!ss) {
            console.error("Falling back to localStorage");
            CordovaService.ss = {
                get: (success: (value: string) => void, error: (error: any) => void, key: string) => {
                    success(S25Util.localStorageGet(key));
                },
                set: (success: () => void, error: (error: any) => void, key: string, value: string) => {
                    S25Util.localStorageSet(key, value);
                    success();
                },
                remove: (success: () => void, error: (error: any) => void, key: string) => {
                    S25Util.localStorageRemove(key);
                    success();
                },
            };
        }
    }

    public static setSecureStorage(key: string, value: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            CordovaService.ss?.set(
                () => {
                    resolve(true);
                },
                (error: any) => {
                    reject(error);
                },
                key,
                value,
            );
        });
    }

    public static getSecureStorage(key: string): Promise<string> {
        return new Promise((resolve, _) => {
            if (!CordovaService.ss) {
                resolve(null);
                return;
            }
            CordovaService.ss?.get(
                (value: string) => {
                    if (value === "null") {
                        value = null;
                    }
                    resolve(value);
                },
                (_: any) => {
                    resolve(null);
                },
                key,
            );
        });
    }

    public static removeSecureStorage(key: string): Promise<boolean> {
        return new Promise((resolve, _) => {
            if (!CordovaService.ss) {
                resolve(true);
                return;
            }
            CordovaService.ss?.remove(
                () => {
                    resolve(true);
                },
                (_: any) => {
                    resolve(true);
                },
                key,
            );
        });
    }

    public static async initJwtTokenHolder(): Promise<void> {
        await S25Const.authDefer.promise;
        const [jwtTokenHolder, error] = await S25Util.Maybe<JwtTokenHolder>(AuthService.getJwtTokenHolder());
        if (error) {
            console.error(error);
            alert("Error saving authentication for re-use");
            return;
        }
        if (jwtTokenHolder) {
            await CordovaService.setSecureStorage(
                CordovaService.S25AppJwtTokenHolder + "." + S25Const.instanceId,
                JSON.stringify(jwtTokenHolder),
            );
            DataAccess.setJwt(jwtTokenHolder.jwtToken);
            CordovaService.autoRefreshJwt(jwtTokenHolder);
        }
    }

    private static async refreshJwtTokenHolder(jwtTokenHolder: JwtTokenHolder): Promise<void> {
        if (this.activeRefresh) {
            const [refreshedJwtTokenHolder, error] = await S25Util.Maybe<JwtTokenHolder>(
                AuthService.refreshJwt(jwtTokenHolder),
            );
            if (error || !refreshedJwtTokenHolder) {
                console.error(error || "No refreshed JWT");
                AuthService.invalidateAuth();
                return;
            }
            await CordovaService.setSecureStorage(
                CordovaService.S25AppJwtTokenHolder + "." + S25Const.instanceId,
                JSON.stringify(refreshedJwtTokenHolder),
            );
            DataAccess.setJwt(jwtTokenHolder.jwtToken);
            CordovaService.checkJwt(refreshedJwtTokenHolder);
        }
    }

    public static autoRefreshJwt(jwtTokenHolder: JwtTokenHolder) {
        if (CordovaService.checkingJwt) {
            return;
        }
        CordovaService.checkingJwt = true;
        this.checkJwt(jwtTokenHolder);
    }

    public static resetJwt() {
        if (!S25Const.inApp) {
            return new Promise((resolve, _) => {
                resolve(null);
            });
        }
        this.activeRefresh = false;
        S25Util.localStorageClear();
        window.ProData.persistentSessionIdParam = null;
        DataAccess.setJwt(null);
        return this.removeSecureStorage(CordovaService.S25AppJwtTokenHolder + "." + S25Const.instanceId);
    }

    private static checkJwt(jwtTokenHolder: JwtTokenHolder) {
        setTimeout(async () => {
            if (this.activeRefresh) {
                if (CordovaService.isExpired(jwtTokenHolder.tokenExpirationUTC, CordovaService.expirationBufferMs)) {
                    console.warn(
                        "JWT Token expired; will attempt refresh",
                        new Date().getTime(),
                        jwtTokenHolder.tokenExpirationUTC,
                        CordovaService.expirationBufferMs,
                    );
                    // Token expired or about to expire
                    if (CordovaService.isExpired(jwtTokenHolder.refreshTokenExpirationUTC, 0)) {
                        console.error("Token completely expired");
                        AuthService.invalidateAuth();
                        return;
                    }
                    await CordovaService.refreshJwtTokenHolder(jwtTokenHolder);
                    return;
                }
                this.checkJwt(jwtTokenHolder); // token still valid
            }
        }, CordovaService.jwtCheckIntervalMs);
    }

    private static isExpired(expirationUTC: string, bufferMs: number) {
        if (!expirationUTC) {
            console.error("No expiration date");
            return true;
        }
        if (!expirationUTC.endsWith("Z")) {
            expirationUTC += "Z";
        }
        const expirationMs = new Date(expirationUTC).getTime();
        if (isNaN(expirationMs)) {
            console.error("Invalid expiration date");
            return true;
        }
        return new Date().getTime() - expirationMs >= bufferMs;
    }
}
