import { S25ItemI } from "./S25ItemI";
import { MultiselectModelI } from "../modules/s25-multiselect/s25.multiselect.component";
import { SearchCriteriaType } from "./SearchCriteriaI";
import { Item } from "./Item";

export namespace MasterDefTag {
    import Ids = Item.Ids;
    export type TagTypeNames =
        | "categories"
        | "customAttributes"
        | "features"
        | "roles"
        | "emailTemplates"
        | "types"
        | "layouts"
        | "requirements"
        | "eventForms";
    export const enum TagType {
        categories = 1,
        custom_attributes = 2,
        email_template = 3,
        features = 4,
        roles = 5,
        requirements = 6,
        layouts = 7,
        types = 8,
        event_forms = 9,
    }

    export enum TagObjectTypeIds {
        EventForm = 11,
        EmailTemplate = 0,
    }

    export type ObjectTypeIds = TagObjectTypeIds | Ids;

    export interface TagWrapperI {
        tag_id: string | number;
        tag_name?: string;
        comment_id?: string | number;
        comment?: string;
        secGroups?: S25ItemI[];
        addedSecGroups?: S25ItemI[];
        removedSecGroups?: S25ItemI[];
        members?: MasterDefI[]; //Record<types, MasterDefI>;
    }

    export interface MasterDefI {
        md_name: types;
        md_type: TagType;
        object_type?: number;
        searchCriteriaName?: SearchCriteriaType["type"];
        displayName?: string;
        formalName?: string;
        active: boolean | 0 | 1;
        items: S25ItemI[]; //from the get
        addedItems?: S25ItemI[]; //used in put
        removedItems?: S25ItemI[]; //used in put
        multiSelectModel?: MultiselectModelI;
        changed?: boolean;
    }

    export interface MdTag {
        tagId: number;
        tagName: string;
        readOnly?: boolean;
    }

    export const enum types {
        event_custom_attributes = "event_custom_attributes",
        event_form_custom_attributes = "event_form_custom_attributes",
        location_custom_attributes = "location_custom_attributes",
        resource_custom_attributes = "resource_custom_attributes",
        organization_custom_attributes = "organization_custom_attributes",
        contact_custom_attributes = "contact_custom_attributes",
        event_categories = "event_categories",
        location_categories = "location_categories",
        resource_categories = "resource_categories",
        organization_categories = "org_categories",
        event_roles = "event_roles",
        contact_roles = "contact_roles",
        event_types = "event_types",
        organization_types = "organization_types",
        event_requirements = "event_requirements",
        location_features = "location_features",
        location_layouts = "location_layouts",
        location_partitions = "location_partitions",
        email_template = "email_template",
        event_forms = "event_forms",
    }
}
