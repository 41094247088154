// No access, Read only, Edit, Full control
export type AccessLevel = AccessLevels.None | AccessLevels.Read | AccessLevels.Edit | AccessLevels.Full;
export type AccessLevelName =
    | AccessLevelNames.None
    | AccessLevelNames.Read
    | AccessLevelNames.Edit
    | AccessLevelNames.Full;
export const enum AccessLevels {
    None = "N",
    Read = "R",
    Edit = "C",
    Full = "F",
}

export const enum AccessLevelNames {
    None = "None",
    Read = "Read",
    Edit = "Change",
    Full = "Full Control",
}

export const AccessLevelValue = {
    [AccessLevels.None]: 0,
    [AccessLevels.Read]: 1,
    [AccessLevels.Edit]: 2,
    [AccessLevels.Full]: 3,
} as const;

export interface Fls {
    CU_ACCT_NUMBERS: AccessLevel;
    CU_COMMENTS: AccessLevel;
    CU_CONT_DEL: AccessLevel;
    CU_CONTACT: AccessLevel;
    CU_ACCT_DEL: AccessLevel;
    CU_ACCOUNT: AccessLevel;
    CU_ACCT_NOTIFY: AccessLevel;
    CU_ACCT_RATING: AccessLevel;
    ACCOUNT_SEARCH: AccessLevel;
    CU_ACCT_PREF: AccessLevel;
    CU_PERM: AccessLevel;
    CU_RATES: AccessLevel;
    CABINET_DELETE: AccessLevel;
    EVENT_CAB: AccessLevel;
    EVENT_STATE: AccessLevel;
    EVENT_COMMERCE: AccessLevel;
    EVENT_DELETE: AccessLevel;
    EVENT_LIST: AccessLevel;
    EVENT_DRAFT: AccessLevel;
    EVENT_NOTIFY: AccessLevel;
    EVENT_SEARCH: AccessLevel;
    EVENT_SECURITY: AccessLevel;
    EVENT_EVS: AccessLevel;
    FOLDER_DELETE: AccessLevel;
    EVENT_FLD: AccessLevel;
    EVENT_INTERFACE: AccessLevel;
    EVENT_NOTES: AccessLevel;
    EVENT_PERM: AccessLevel;
    EVENT_BILLING: AccessLevel;
    EVENT_RESOURCE: AccessLevel;
    EVENT_SHARE: AccessLevel;
    EVENT_SPACE_PROFILE: AccessLevel;
    SPEEDBOOK: AccessLevel;
    EVENT_TEXT: AccessLevel;
    EVENT_ACTION: AccessLevel;
    IMPORT: AccessLevel;
    IMP_PROFILE: AccessLevel;
    SIS_INTERFACE: AccessLevel;
    RUNS25: AccessLevel;
    M25_EXPORT: AccessLevel;
    VCAL_EXPORT: AccessLevel;
    VCAL_IMPORT: AccessLevel;
    VCAL_PUB: AccessLevel;
    MANAGE_PAY: AccessLevel;
    ML_CONTACT: AccessLevel;
    ML_DATE: AccessLevel;
    ML_EVENT: AccessLevel;
    ML_EVENT_TYPES: AccessLevel;
    IMP_FILTER: AccessLevel;
    ML_ACCOUNT: AccessLevel;
    ML_PRICE: AccessLevel;
    ML_REPORT: AccessLevel;
    ML_RESOURCE: AccessLevel;
    ML_SPACE: AccessLevel;
    REP_PERM: AccessLevel;
    REP_LIST: AccessLevel;
    REP_ADMIN: AccessLevel;
    REPORT_SECURITY: AccessLevel;
    RESOURCE_ASSIGN_OVER: AccessLevel;
    RESOURCE_PERM: AccessLevel;
    RESOURCE_ASSIGN: AccessLevel;
    RESOURCE_DEL: AccessLevel;
    RESOURCE_LIST: AccessLevel;
    RESOURCE_NOTIFY: AccessLevel;
    RESOURCE_SEARCH: AccessLevel;
    RESOURCE_SECURITY: AccessLevel;
    TRACE: AccessLevel;
    CHG_PSWD: AccessLevel;
    LICENSE: AccessLevel;
    LOCK: AccessLevel;
    SECURITY: AccessLevel;
    UPGRADE: AccessLevel;
    // 2.2 Inline Editing on Event Details
    WEBSECURITY: AccessLevel;
    SPACE_VIEW: AccessLevel;
    SPACE_ASSIGN_OVER: AccessLevel;
    SPACE_BLACK: AccessLevel;
    SPACE_BLOCKED: AccessLevel;
    SPACE_HOURS: AccessLevel;
    SPACE_PERM: AccessLevel;
    SPACE_S25: AccessLevel;
    SPACE_ASSIGN: AccessLevel;
    SPACE_DEL: AccessLevel;
    SPACE_LIST: AccessLevel;
    SPACE_NOTIFY: AccessLevel;
    SPACE_OPEN: AccessLevel;
    SPACE_SEARCH: AccessLevel;
    SPACE_SECURITY: AccessLevel;
    SYS_CONTACTS: AccessLevel;
    SYS_DATAENTRY: AccessLevel;
    SYS_DATETIME: AccessLevel;
    SYS_DISPLAY: AccessLevel;
    SYS_ECOMMERCE: AccessLevel;
    SYS_EMAIL: AccessLevel;
    SYS_EVENTPROC: AccessLevel;
    SYS_MOTD: AccessLevel;
    SYS_MYR25: AccessLevel;
    SYS_ACCT: AccessLevel;
    SYS_RATE: AccessLevel;
    SYS_REPORT: AccessLevel;
    SYS_RESOURCE: AccessLevel;
    SYS_SQL: AccessLevel;
    SYS_UAI: AccessLevel;
    SYS_POLICY: AccessLevel;
    SYS_SPACE: AccessLevel;
    SYS_WV: AccessLevel;
    SYS_VCAL: AccessLevel;
    CREATE_TODO: AccessLevel;
    EMAIL: AccessLevel;
    TASK_LIST: AccessLevel;
    TASK_CANCEL: AccessLevel;
    VIEW_OTHERS: AccessLevel;
    QUOTA_OVERRIDE: AccessLevel;
    ACCOUNT_SECURITY: AccessLevel;
    SPACE_RATING: AccessLevel;
    CAL_OUTLOOK: AccessLevel;
    CAL_GOOGLE: AccessLevel;
    EVENT_OCC_DIRECT: AccessLevel;
    ACADEMIC_GRIDS: AccessLevel;
}

export function isMinFls(accessLevel: AccessLevels, min: AccessLevels): boolean {
    return AccessLevelValue[accessLevel] >= AccessLevelValue[min];
}

export function hasFullFls(accessLevel: AccessLevels): boolean {
    return isMinFls(accessLevel, AccessLevels.Full);
}

export function hasEditFls(accessLevel: AccessLevels): boolean {
    return isMinFls(accessLevel, AccessLevels.Edit);
}

export function hasReadFls(accessLevel: AccessLevels): boolean {
    return isMinFls(accessLevel, AccessLevels.Read);
}
